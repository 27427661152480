import { css } from "styled-components";

export const breakpoints = {
  sp: ({ theme }) => theme.mobileWidth,
  spMin: ({ theme }) => theme.mobileWidth,
  tb: ({ theme }) => theme.tabletWidth,
  tbMin: ({ theme }) => theme.tabletWidth,
  pc: ({ theme }) => theme.desktopWidth,
  pcMin: ({ theme }) => theme.desktopWidth,
};

// Media query mixin respond to each breakpoints
export const mq = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => {
    return label.includes("Min")
      ? css`
          @media (min-width: ${breakpoints[label]}) {
            ${css(...args)};
          }
        `
      : css`
          @media (max-width: ${breakpoints[label]}) {
            ${css(...args)};
          }
        `;
  };

  return accumulator;
}, {});
