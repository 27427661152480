import React from "react";
import styled from "styled-components";

export const MenuItem = ({ i, name }) => {
  return <StyledMenuItem i={i}>{name}</StyledMenuItem>;
};

const StyledMenuItem = styled.span`
  color: white;
`;
