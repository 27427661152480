import styled from "styled-components";
import { mq } from "../../styles/mixin";

export const StyledAnotherAngle = styled.div`
  position: relative;
  .outline {
    font-family: ${({ theme }) => theme.outlineFont};
    position: absolute;
    z-index: -1;
    top: -15%;
    left: 52%;
    ${mq.sp`
      position: relative;
      z-index: 0;
      left: 0;
      top: 0;
    `}
  }

  p {
    line-height: 1;
  }

  .message-box {
    margin-top: 200px;
    padding-left: 12rem;
    margin-bottom: 20rem;
    ${mq.sp`
      margin-top: 83px;
      padding-left: 0;
    `}
  }

  .japanese_message {
    letter-spacing: 0.1rem;
    line-height: 2.7;
    font-size: 2.7rem;
    margin-top: 6rem;
    font-family: ${({ theme }) => theme.jpFont};
    ${mq.sp`
      padding-left: 1rem;
      background-size: 100%;
      font-size: 1.5rem;
      letter-spacing: 2px;
    `}
  }

  .english_message {
    font-weight: 100;
    font-size: 2rem;
    line-height: 2.4;
    margin-top: 2.2rem;
    letter-spacing: 2px;
    ${mq.sp`
      margin-top: 3.3rem;
      background-size: 100%;
      padding-left: 1rem;
      font-size: 1.4rem;
      line-height: 2.8;
    `}
  }
`;
