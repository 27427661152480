import React from "react";

import { StyledLinearIndicator } from "./StyledLinearIndicator";

export const LinearIndicator = ({ size }) => {
  const trimmedSize = size => {
    if (size <= 0) {
      return 0;
    }
    if (size >= 100) {
      return 100;
    }
    return size;
  };

  return (
    <StyledLinearIndicator size={trimmedSize(size)}>
      <div className="main"></div>
      <div className="clear"></div>
    </StyledLinearIndicator>
  );
};
