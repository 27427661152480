import styled from "styled-components";

import { mq } from "../../styles/mixin";

export const StyledMember = styled.div`
  &:not(:last-child) {
    margin-top: 6rem;
    margin-bottom: 16rem;
    ${mq.sp`
      margin-bottom: 12rem;
    `}
  }
  .member {
    position: relative;
    max-width: 114rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${mq.tb`
      flex-direction: column;
      align-items: center;
      padding-left: 1rem;
      letter-spacing: 4px;
    `}
    ${mq.sp`
      align-items: flex-start;
      padding-left: 1.8rem;
      padding-right: 1.8rem;
    `}
    &__abs {
      ${mq.tbMin`
        position: absolute;
        top: 0;
        transform: translateY(50%);
      `}
    }
    &__text {
      padding-left: 18rem;
      h4 {
        font-size: 2rem;
        margin-bottom: 4.5rem;
        ${mq.sp`
          margin: 3rem 0;
        `}
      }
      ${mq.sp`
        align-self: center;
        width: 28.5rem;
        padding-left: 0;
      `}
      .text--red {
        letter-spacing: 0.2rem;
        margin-right: 2rem;
        ${mq.sp`
          font-size: 1.5rem;
          letter-spacing: 0.2rem;
          margin-right: 1rem;
        `}
      }
    }

    &__copy {
      /* position: absolute; */
      font-size: 6rem;
      font-weight: 700;
      line-height: 1.2;
      white-space: nowrap;
      margin-bottom: 4rem;
      letter-spacing: 0.7rem;
      cursor: pointer;
      ${mq.sp`
        font-size: 2.4rem;
        margin-bottom: 3rem;
        line-height: 1.6;
        letter-spacing: 0.3rem;
      `};
    }
    &__position {
      color: ${({ theme }) => theme.secondaryGrey};
      ${mq.sp`
        margin-bottom: 2rem;
        font-size: 1.2rem;
        font-weight: 100;
        line-height: 1.6;
        letter-spacing: 0.1rem;
      `}
    }
    &__image {
      width: 41rem;
      cursor: pointer;
      ${mq.sp`
        max-width: 28.5rem;
        align-self: center;
      `}
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .social {
    a {
      color: ${({ theme }) => theme.primaryGrey};
      font-size: 3rem;
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
    ${mq.sp`
      margin-bottom: 2rem;
    `}
  }
`;
