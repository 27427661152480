import React from "react";
import { bool, func, string } from "prop-types";
import { useLocation } from "react-router-dom";

import { StyledHeader } from "./StyledHeader";
import { Logo } from "../Logo";
import { Burger } from "../Burger";
import { Menu } from "../Menu";

export const Header = ({ open, setOpen }) => {
  const location = useLocation();
  return (
    <StyledHeader page={location.pathname}>
      <Logo />
      <Burger open={open} setOpen={setOpen} />
      <Menu open={open} setOpen={setOpen} />
    </StyledHeader>
  );
};

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
  page: string,
};
