import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Outline } from "../Outline";
import { StyledFooter } from "./StyledFooter";
import { Logo } from "../Logo";
import { github, facebook } from "../Icons";

export const Footer = () => (
  <StyledFooter>
    <div id="our-union-lab" className="container">
      <div className="inner">
        <Outline title="Our Union Lab" />
        <p className="heading">
          Experiment place <span className="sp-break" />
          for <span className="pc-break" />
          geek crazy
          <span className="sp-break" /> creator
        </p>
      </div>
      <div className="info inner">
        <Logo color="black" />
        <div className="contact">
          <p>info-jirettai@gmail.com</p>
          <p>080-4265-9971</p>
        </div>
        <div className="address">
          <p>東京都渋谷区渋谷3-13-5 BPR1109</p>
          <div>
            <a className="icon" href="https://github.com/jirettai">
              <FontAwesomeIcon icon={github} />
            </a>
            <a className="icon" href="https://facebook.com/jirettai">
              <FontAwesomeIcon icon={facebook} />
            </a>
          </div>
        </div>
      </div>
    </div>
  </StyledFooter>
);
