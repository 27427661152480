import styled from "styled-components";
import { mq } from "../../styles/mixin";

export const StyledGlobalNavigation = styled.div`
  a {
    cursor: pointer;
    font-size: 4rem;
    white-space: nowrap;
  }

  .outline {
    opacity: 0.6;
    font-family: ${({ theme }) => theme.outlineFont};
    position: absolute;
    z-index: -1;
    top: 55%;
    left: 52%;
    ${mq.sp`
    position: relative;
    z-index: 0;
    top: 5%;
    left: 18%;
  `}

    h3 {
      font-size: 12rem;
      ${mq.tb`
        font-size: 9rem;
      `}
      ${mq.sp`
        font-size: 7.5rem;
      `}
    }
  }

  .vertical-text {
    font-weight: 300;
    position: absolute;
    top: 50%;
    right: 3rem;
  }
`;
