import styled from "styled-components";

export const StyledLogo = styled.div`
  max-height: 4rem;
  max-width: 16.5rem;
  img {
    width: 100%;
    height: 100%;
  }
`;
