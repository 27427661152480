import styled from "styled-components";

import { mq } from "../../styles/mixin";
import image from "../../images/members.jpg";

export const StyledParallax = styled.div`
  position: relative;
  background-image: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  height: 88rem;
  width: 100vw;
  margin-bottom: 13rem;
  ${mq.sp`
    height: 60rem;
    background-size: 100%;
  `}
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }
  .parallax-content {
    position: absolute;
    top: 50%;
    left: 41%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    letter-spacing: 0.25rem;
    ${mq.sp`
      top: 75%;
      left: 50%;
      width: 28.5rem;
    `}
  }
  h3 {
    text-transform: uppercase;
    letter-spacing: 5px;
  }
  .japanese {
    font-size: 6rem;
    line-height: 8rem;
    ${mq.sp`
      font-size: 3.7rem;
    `}
  }
  .english {
    line-height: 3rem;
    font-weight: 200;
    color: ${({ theme }) => theme.secondaryGrey};
    ${mq.sp`
      font-size: 1.6rem;
    `}
  }
`;
