export const members = [
  {
    name: "Masatoshi Oliver Mizogaki",
    copy: "Shanghai Big G",
    cigar: "cohiba",
    position: "CEO - Engineer",
    image: "mizogaki.jpg",
    social: {
      github: "https://github.com/Mizogaki",
    },
  },
  {
    name: "Soichi David Nakahashi",
    copy: "Paris Master Roshi",
    cigar: "monterrey",
    position: "COO - Engineer",
    image: "david.jpg",
    social: {
      github: "https://github.com/sdn0303",
    },
  },
  {
    name: "Kosuke Abdul Obata",
    copy: "New York Mewtwo",
    cigar: "partagas",
    position: "CXO - Designer",
    image: "kosuke.jpg",
    social: {
      github: "https://github.com/KSK3815",
    },
  },
  {
    name: "Shun Wilson Kitamachi",
    copy: "Helsinki Whitey",
    cigar: "montecresto",
    position: "Art Director - Designer",
    image: "shun.jpg",
    social: {
      github: "https://github.com/sshun",
    },
  },
  {
    name: "Yasushi Astin Ikeda",
    copy: "Vancouver Noh-Face",
    cigar: "punch",
    position: "Front-end Engineer",
    image: "yasu.jpg",
    social: {
      github: "https://github.com/yikeda6616",
    },
  },
  {
    name: "Yoha Nikita Oishi",
    copy: "Vienna Howl",
    cigar: "Romeo",
    position: "Engineer",
    image: "yoha.jpg",
    social: {
      github: "https://github.com/yoha-o",
    },
  },
  {
    name: "Shohei Jesse Kikuchi",
    copy: "Moscow Ezreal",
    cigar: "Ramon Allones",
    position: "Engineer",
    image: "shope.jpg",
    social: {
      github: "https://github.com/skikuchi7329",
    },
  },
];
