import styled from "styled-components";

import { mq } from "../../styles/mixin";

export const StyledFooter = styled.footer`
  min-height: 80rem;
  color: ${({ theme }) => theme.primaryDark};
  background-color: ${({ theme }) => theme.primaryWhite};

  .container {
    padding: 2rem;
    padding-top: 20rem;
    max-width: 1200px;

    ${mq.sp`
    padding-top: 6rem;
    max-width: 480px;
    `}
  }

  .outline {
    font-size: 15rem;
    transform: translateX(-15rem);

    ${mq.sp`
    font-size: 6rem;
    transform: translateX(-6rem);
    margin-left: 4rem; 
    `}
  }

  .heading {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 6rem;
    margin-top: -4.5rem;
    padding-bottom: 10rem;
    margin-left: 15%;
    letter-spacing: 0.7rem;
    transform: translateX(-5rem);
    line-height: 8.4rem;
    ${mq.sp`
    font-size: 2.8rem;
    transform: translateX(-3rem);
    margin-left: 3.5rem;
    padding-top: 6rem;
    padding-bottom: 5rem;
    line-height: 1.3;
    letter-spacing: 0.2rem;
    `}
  }

  .info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${mq.sp`
      flex-direction: column;
    `}
  }

  .contact {
    width: 36rem;
    padding-top: 1rem;
    border-top: 1px solid ${({ theme }) => theme.primaryHighlight};

    ${mq.sp`
    width: 34rem;
    line-height: 3rem;
    margin-top: 6rem;
    border-top: 1px solid ${({ theme }) => theme.primaryHighlight};
    `}
  }

  .address {
    width: 36rem;
    padding-top: 1rem;
    border-top: 1px solid ${({ theme }) => theme.primaryHighlight};
    font-weight: 500;

    ${mq.sp`
    border-top: none;
    padding-top: 0;
    line-height: 3rem;
    `}
  }

  .inner {
    width: 100%;
  }

  .icon {
    padding-right: 0.5rem;
    color: black;

    ${mq.sp`
    padding-right: 1rem;
    `}
  }
`;
