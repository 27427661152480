import React from "react";
import styled from "styled-components";

import { mq } from "../../../styles/mixin";

export const NumberLabel = ({ index, label }) => {
  return (
    <StyledNumberLabel>
      <span>0{index}</span> {label}
    </StyledNumberLabel>
  );
};

const StyledNumberLabel = styled.h3`
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 3rem;
  white-space: nowrap;
  span {
    color: ${({ theme }) => theme.primaryHighlight};
    margin-right: 1.3rem;
    ${mq.sp`
      margin-right: 1rem;
    `}
  }
`;
