import React from "react";

import { NumberLabel } from "../_atoms/NumberLabel";
import { StyledParallax } from "./StyledParallax";
import { multipleLineText } from "../../util";

export const Parallax = () => {
  return (
    <StyledParallax>
      <div className="parallax-content">
        <NumberLabel index="0" label="JIRETTAI" />
        <div className="japanese mb-3">
          {multipleLineText("We build for <br>geek crazy creator")}
        </div>
        <div className="english">
          {multipleLineText(
            "I have ever By chance, <br>Ihave never made a valuable invention."
          )}
        </div>
      </div>
    </StyledParallax>
  );
};
