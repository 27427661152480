import styled from "styled-components";

import { mq } from "../../styles/mixin";

export const StyledOutline = styled.h3`
  color: transparent;
  font-weight: 500;
  font-size: 15rem;
  -webkit-text-stroke: 1px ${({ theme }) => theme.primaryHighlight};
  font-family: ${({ theme }) => theme.outlineFont};
  ${mq.sp`
    font-size: 6rem;
  `}
  p {
    line-height: 0.9;
    ${mq.sp`
      line-height: 1;
    `}
  }
`;

export const StyledFooterOutline = styled.h3`
  color: transparent;
  font-weight: 500;
  font-size: 15rem;
  text-shadow: 2px 6px 2px #fafafa, 0 0 #f0f0f0;
  font-family: ${({ theme }) => theme.outlineFont};
  -webkit-text-stroke: 1px ${({ theme }) => theme.primaryHighlight};
  ${mq.sp`
    font-size: 6rem;
  `}
`;

export const StyledOutlineRight = styled.h3`
  color: transparent;
  font-weight: 500;
  font-size: 15rem;
  font-family: ${({ theme }) => theme.outlineFont};
  -webkit-text-stroke: 1px ${({ theme }) => theme.primaryHighlight};
  ${mq.pc`
    text-align: right;
  `}
  ${mq.sp`
    text-align: left;
    margin: 0 auto;
    font-size: 9.5rem;
  `}
`;
