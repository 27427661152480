import React from "react";
import PropTypes from "prop-types";

import {
  StyledFooterOutline,
  StyledOutline,
  StyledOutlineRight,
} from "./StyledOutline";
import { multipleLineText } from "../../util";

export const Outline = ({ align, title }) =>
  align === "right" ? (
    <StyledOutlineRight>{multipleLineText(title)}</StyledOutlineRight>
  ) : title === "Our Union Lab" ? (
    <StyledFooterOutline>{multipleLineText(title)}</StyledFooterOutline>
  ) : (
    <StyledOutline>{multipleLineText(title)}</StyledOutline>
  );

Outline.propTypes = {
  align: PropTypes.string,
  title: PropTypes.string,
};
