import React from "react";

import { NumberLabel } from "../_atoms/NumberLabel";

import { multipleLineText } from "../../util";
import { StyledProduct } from "./StyledProduct";

export const Product = ({ image, title, label, index, text }) => (
  <StyledProduct className="product">
    <div className="bg-image">{image && <img src={image} alt={title} />}</div>
    <div className="text-block text-left">
      <NumberLabel index={index} label={label} />
      <p className="title">{title}</p>
      <div className="text">{multipleLineText(text)}</div>
    </div>
  </StyledProduct>
);
