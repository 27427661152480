import React from "react";
import styled from "styled-components";

import { mq } from "../../styles/mixin";
import { SectionLabel } from "../SectionLabel";
import { Product } from "../Product";
import image1 from "../../images/products/infinite-product.png";
import image2 from "../../images/products/geek-brew.png";

const products = [
  {
    title: "Infinite Products.",
    label: "Π∞ n=1 Xn",
    text: "xn ≠ 0 (n > m) pn := xm+1 … xn (n > m) <br> limn→∞ xn = 1",
    image: image1,
  },
  {
    title: "Geek Brew",
    label: "Engineer Online Community",
    text:
      "From an engineer at a well-known company An <br> online community where you can learn practical skills",
    image: image2,
  },
  {
    title: "Black Organization",
    label: "Cigar D2C Platform",
    text:
      "From an engineer at a well-known company An <br> online community where you can learn practical skills",
    image: null,
  },
];

export const OurProducts = () => (
  <StyledOurProducts id="our-products">
    <div className="sectionLabelContainer">
      <SectionLabel
        className="invisible-sp"
        title="Our<br>products"
        verticalText="Products for creater"
        jpHeading="未来の発明は、実験場から<br>型を壊して生まれてくる。"
        enHeading="Future inventions from the experimental site<br>Born by breaking the mold."
      />
      <SectionLabel
        className="visible-sp"
        title="Our<br>products"
        verticalText="Products for creater"
        jpHeading="未来の発明は、実験場から<br>型を壊して生まれてくる。"
        enHeading="Future inventions<br>from the experimental site<br>Born by breaking the mold."
      />
    </div>
    <div className="products container-fluid p-0">
      {products.map((v, i) => (
        <Product key={i} {...v} index={i + 1} />
      ))}
    </div>
  </StyledOurProducts>
);

const StyledOurProducts = styled.div`
  padding: 0 10rem 11.5rem 10rem;
  ${mq.sp`
    padding: 0;
  `}
  max-width: ${({ theme }) => theme.containerWidth};
  margin: 0 auto;
  .sectionLabelContainer {
    margin-left: 2rem;
  }
  .products {
    .product {
      height: 686px;
      margin-bottom: 17rem;
      ${mq.sp`
      margin-left: 2rem;
      margin-bottom: 10rem;
      `}
    }
  }
`;
