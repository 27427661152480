import styled from "styled-components";

import { mq } from "../../styles/mixin";

export const StyledProduct = styled.div`
  position: relative;
  .bg-text {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    top: 0;
    left: 0;
    font-size: 71rem;
    color: ${({ theme }) => theme.darkGrey};
    white-space: nowrap;
  }
  .bg-image {
    position: absolute;
    top: 20%;
    left: 55%;
    z-index: -1;
  }
  .text-block {
    padding-left: 8vw;
    /* padding-top: 24rem; */
    transform: translateY(100%);
    ${mq.sp`
      width: 28.5rem;
      margin: 0 auto;
      padding-left: 0;
    `}
    .title {
      font-size: 6rem;
      letter-spacing: 6px;
      margin-bottom: 5rem;
      font-variant-ligatures: common-ligatures;
      ${mq.sp`
        font-size: 3.7rem;
        margin-bottom: 4rem;
        line-height: 1.3;
      `}
    }
    .text {
      p {
        margin-bottom: 0;
      }
      line-height: 4rem;
      font-weight: 300;
      text-align: left;
      letter-spacing: 3px;
      color: ${({ theme }) => theme.secondaryGrey};
      ${mq.sp`
        line-height: 3rem;
      `}
    }
  }
`;
