import React from "react";
import styled from "styled-components";

import PropTypes from "prop-types";
import { mq } from "../../../styles/mixin";

export const MemberLabel = ({ index }) => {
  return (
    <StyledMemberLabel>
      <span className="member">Member</span>
      <span>0{index}</span>
    </StyledMemberLabel>
  );
};

const StyledMemberLabel = styled.h3`
  text-align: left;
  margin-bottom: 3rem;
  white-space: nowrap;
  span.member {
    color: ${({ theme }) => theme.primaryHighlight};
    margin-right: 1.3rem;
    ${mq.sp`
      margin-right: 1rem;
    `}
  }
`;

MemberLabel.propTypes = {
  index: PropTypes.number,
};
