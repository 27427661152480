import styled from "styled-components";

export const StyledLinearIndicator = styled.div`
  position: relative;
  height: 100%;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);

  .main,
  .clear {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .main {
    z-index: 1;
    width: ${({ size }) => size + "%"};
    height: 2px;
    background: #e85b6b;
  }

  .clear {
    width: 100%;
    height: 2px;
    margin: auto;
    background: #bbb;
  }
`;
