import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled, { ThemeProvider, css } from "styled-components";

import { GlobalStyle } from "./styles/GlobalStyle";
import { theme } from "./styles/theme";
import { Home } from "./Home";
import { Header } from "./elements/Header";
import { MemberPage } from "./pages/MemberPage";

const scrollPreventMixin = css`
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
`;

const StyledWrapper = styled.div`
  position: relative;
  ${({ open }) => (open ? scrollPreventMixin : "")}
`;

function App() {
  const [open, setOpen] = useState(false);
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <StyledWrapper open={open}>
          <GlobalStyle />
          <Header open={open} setOpen={setOpen} />
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/services" component={Services} />
            <Route path="/contacts" component={Contacts} />
            <Route path="/members/:id" component={MemberPage} />
            <Route path="/released" component={Released} />
            <Route path="/product" component={Product} />
            <Route path="*" component={Home} />
          </Switch>
        </StyledWrapper>
      </ThemeProvider>
    </Router>
  );
}

export default App;

const Services = () => <h1>Services</h1>;
const Contacts = () => <h1>Contacts</h1>;
const Released = () => <h1>Released</h1>;
const Product = () => <h1>Product</h1>;
