export const theme = {
  primaryDark: "#0D0D10",
  primaryLight: "#efefef",
  primaryHover: "#cdcdcd",
  primaryHighlight: "#E85B6B",
  primaryWhite: "#fff",
  primaryGrey: "#888",
  secondaryGrey: "#dadada",
  darkGrey: "#1B1A1E",
  mobileWidth: "576px",
  tabletWidth: "998px",
  desktopWidth: "1366px",
  containerWidth: "1366px",
  jpFont: "Yu-Gothic-Bold, YuGothic, Yu Gothic, sans-serif",
  outlineFont: "STHeiti",
};
