import styled from "styled-components";
import { mq } from "../../styles/mixin";

const StyledSkillTable = styled.div`
  font-family: ${({ theme }) => theme.outlineFont};
  margin: 16rem auto;
  margin-left: 10rem;
  ${mq.sp`
    margin-left: 0;
  `}
  .header {
    p {
      span {
        color: ${({ theme }) => theme.primaryHighlight};
        margin-right: 1.6rem;
      }
    }
    h3 {
      font-weight: 600;
      font-size: 6rem;
      ${mq.sp`
        font-size: 3rem;
      `}
    }
    margin-bottom: 3rem;
    ${mq.sp`
      margin-bottom: 0;
    `}
  }
  table {
    width: 100%;
    ${mq.sp`
          line-height: 1.6;
          font-size: 1.2rem;
        `}
    thead {
      color: ${({ theme }) => theme.primaryHighlight};
      tr {
        display: flex;
        line-height: 6rem;
        th {
          width: 30%;
          font-weight: 400;
          ${mq.sp`
            min-width: 8rem;
            height: 39px;
            line-height: 3;
          `}
          &:nth-child(2) {
            width: 20%;
          }
          &:nth-child(3) {
            width: 50%;
          }
        }
      }
    }
    tbody {
      color: ${({ theme }) => theme.primaryGrey};
      tr {
        display: flex;
        line-height: 6rem;
        td {
          width: 30%;
          font-size: 14px;
          font-weight: 400;
          color: ${({ theme }) => theme.secondaryGrey};
          ${mq.sp`
          min-width: 8rem;
          line-height: 3;
          font-size: 1.2rem;
        `}
          &:nth-child(2) {
            width: 20%;
          }
          &:nth-child(3) {
            width: 50%;
          }
        }
      }
    }
  }
`;

export { StyledSkillTable };
