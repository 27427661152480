import React from "react";
import PropTypes from "prop-types";
import { StyledSkillTable } from "./index.styled";
import { LinearIndicator } from "../../elements/_atoms/LinearIndicator";

const SkillTable = ({ header, arr, index }) => {
  return (
    <StyledSkillTable>
      <div className="header">
        <p>
          <span>0{index}</span> Skills
        </p>
        <h3>{header}</h3>
      </div>

      <table>
        <thead>
          <tr>
            <th>Property</th>
            <th>Term</th>
            <th>Mastery</th>
          </tr>
        </thead>
        <tbody>
          {arr.map(({ property, experience, mastery }, index) => (
            <tr key={index}>
              <td>{property}</td>
              <td>{experience} years</td>
              <td>
                <LinearIndicator size={mastery} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </StyledSkillTable>
  );
};

export { SkillTable };

SkillTable.propTypes = {
  index: PropTypes.number,
};
