import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { mq } from "../../styles/mixin";

export const ScrollIndicator = () => {
  return (
    <motion.div animate={{ scale: 1 }}>
      <StyledScrollIndicator className="vertical">
        Scroll
        <motion.span
          animate={{
            translateY: [0, 15],
            opacity: 0,
          }}
          transition={{
            duration: 2,
            ease: "easeOut",
            times: [0, 1],
            loop: Infinity,
            repeatDelay: 1,
          }}
        />
      </StyledScrollIndicator>
    </motion.div>
  );
};
const StyledScrollIndicator = styled.span`
  cursor: default;
  font-size: 10px;
  font-weight: 300;
  letter-spacing: 8px;
  position: fixed;
  top: 40vh;
  right: 6%;
  text-transform: uppercase;
  z-index: 1;
  ${mq.tb`
    display: none;
  `}
  span {
    content: "";
    position: absolute;
    top: 100%;
    right: 50%;
    transform: translateX(-50%);
    height: 3rem;
    width: 0.5px;
    background-color: ${({ theme }) => theme.primaryWhite};
  }
`;
