import styled from "styled-components";
import oliverHero from "../../images/memberPage/oliver-hero.png";
import davidHero from "../../images/memberPage/david-hero.jpg";
import abdulHero from "../../images/memberPage/abdul-hero.jpg";
import wilsonHero from "../../images/memberPage/wilson-hero.jpg";
import astinHero from "../../images/memberPage/astin-hero.jpg";
import nikitaHero from "../../images/memberPage/nikita-hero.jpg";
import jesseHero from "../../images/memberPage/jesse-hero.jpg";
import { mq } from "../../styles/mixin";

const heroImages = {
  oliver: oliverHero,
  david: davidHero,
  abdul: abdulHero,
  wilson: wilsonHero,
  astin: astinHero,
  nikita: nikitaHero,
  jesse: jesseHero,
};

const imagePosition = member => {
  return member === "oliver"
    ? "95% 0"
    : member === "david"
    ? "62% 0"
    : member === "abdul"
    ? "65% 0"
    : member === "wilson"
    ? "83% 0"
    : member === "astin"
    ? "25% 0"
    : member === "nikita"
    ? "46% 0"
    : member === "jesse"
    ? "57% 0"
    : "";
};

const StyledHero = styled.div`
  background-image: url(${({ member }) => heroImages[member]});
  background-position: ${({ member }) => imagePosition(member)};
  background-repeat: no-repeat;
  background-size: cover;
  height: 800px;
  width: 100vw;

  display: flex;
  align-items: center;
  padding-left: 12rem;
  ${mq.sp`
    padding-left: 4rem;
  `}

  .text-wrapper {
    h1 {
      font-size: 6rem;
      font-weight: 700;
      ${mq.sp`
        margin-bottom: 10px;
        font-size: 3rem;
      `}
    }
    p {
      font-size: 2.4rem;
      letter-spacing: 1.2px;
      color: ${({ theme }) => theme.secondaryGrey};
      font-family: ${({ theme }) => theme.outlineFont};
      ${mq.sp`
        font-size: 1.2rem;
      `}
    }
  }
`;

export { StyledHero };
