import styled from "styled-components";

import { mq } from "../../styles/mixin";

export const StyledSectionLabel = styled.div`
  position: relative;

  .vertical {
    position: absolute;
    top: ${({ wide }) => (wide ? "40rem" : "25rem")};
    ${mq.sp`
      top: ${({ wide }) => (wide ? "20rem" : "12rem")}
    `}
    left: 4rem;
    font-size: 1rem;
    letter-spacing: 0.8em;
    white-space: nowrap;
    ${mq.sp`
      left: 0rem;
      top: 17rem;
    `}
    &::before {
      content: "";
      position: absolute;
      top: 105%;
      left: 0.6rem;
      width: 0.1rem;
      height: 10rem;
      background-color: ${({ theme }) => theme.primaryWhite};
    }
  }

  .text-block {
    padding-left: 10rem;
    font-weight: 500;
    line-height: 5rem;
    transform: translateY(-30px);
    ${mq.sp`
      padding-left: 3.4rem;
      margin-top: 5.5rem;
    `}
  }
  .about-japanese {
    font-family: ${({ theme }) => theme.jpFont};
    font-weight: 700;
    font-size: 3rem;
    letter-spacing: 0.3rem;
    margin-bottom: 5rem;
    ${mq.sp`
      margin-bottom: 3rem;
      font-size: 2rem;
      line-height: 3.2rem;
    `}
  }
  .about-english {
    line-height: 4rem;
    font-size: 2rem;
    font-weight: 300;
    letter-spacing: 0.12em;
    letter-spacing: 0.2rem;
  }
`;
