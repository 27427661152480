import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { StyledMember } from "./StyledMember";
import { github } from "../Icons";

export const Member = ({
  index,
  copy,
  cigar,
  name,
  position,
  social,
  image,
}) => {
  let history = useHistory();
  const handleLinkButton = name => {
    history.push(`/members/${memberRouteName(name)}`);
    window.scroll({ top: 0 });
  };

  const memberRouteName = name => name.split(" ")[1].toLowerCase();
  return (
    <StyledMember>
      <div className="member">
        <div className="member__text">
          <div className="member__abs">
            <h4>
              <span className="text--red">Member</span>0{index + 1}
            </h4>
            <p className="member__copy" onClick={() => handleLinkButton(name)}>
              {copy} <br />
              {cigar} cigar
            </p>

            <p className="member__position">
              {name} / {position}
            </p>
            <p className="social">
              <a href={social.github}>
                <FontAwesomeIcon icon={github} />
              </a>
            </p>
          </div>
        </div>
        <div className="member__image" onClick={() => handleLinkButton(name)}>
          <img src={require(`../../images/${image}`)} alt={name + " image"} />
        </div>
      </div>
    </StyledMember>
  );
};

Member.propTypes = {
  index: PropTypes.number,
  copy: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
  image: PropTypes.string,
  social: PropTypes.object,
};
