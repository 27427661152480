import styled from "styled-components";

import { mq } from "../../styles/mixin";

export const StyledTopView = styled.div`
  margin: 6rem auto;
  padding: 0px 10rem;
  max-width: 1366px;
  font-style: normal;
  ${mq.sp`
    margin: 0 auto;
    padding: 8.3rem 2rem 5rem;
  `}

  .group_image {
    text-align: center;
    max-width: 1300px;
    ${mq.sp`
      display: none;
    `}
  }

  .group_image_sp_upper {
    display: none;
    ${mq.sp`
      display: block;
      text-align: center;
    `}
  }

  .group_image_sp_lower {
    display: none;
    ${mq.sp`
      display: block;
      text-align: center;
      margin-top: 4rem;
    `}
  }

  .title_text {
    padding-left: 12rem;
    ${mq.sp`
      padding: 0;
      margin-top: -5rem;
      margin-left: 0.6rem;
    `}
  }

  .japanese {
    margin-left: 0.6rem;
    font-size: 7rem;
    font-family: ${({ theme }) => theme.jpFont};
    line-height: 10.6rem;
    letter-spacing: 2.8rem;
    ${mq.sp`
      margin-left: 0;
      font-size: 3.7rem;
      letter-spacing: 1.45rem;
      line-height: 6rem;
    `}
  }

  .english {
    color: white;
    font-weight: 300;
    font-size: 1.6rem;
    letter-spacing: 1rem;
    margin-top: 4.5rem;
    margin-bottom: 1rem;
    ${mq.sp`
      letter-spacing: 0.4rem;
      margin-top: 2.5rem;
      margin-bottom: 0;
      font-size: 1.2rem;
    `}
  }
`;
