import React from "react";

import { StyledMembers } from "./StyledMembers";
import { SectionLabel } from "../SectionLabel";
import { Member } from "../Member";
import { members } from "./membersInfo.js";

export const Members = () => {
  return (
    <StyledMembers
      id="about-us"
      className="container sectionContainer StyledMembers"
    >
      <SectionLabel
        title="About us"
        verticalText="we are geek creazy creater"
        jpHeading="自分たちの手で新しい型を作り<br>そしてそれさえも壊し続ける"
        enHeading="Create a new mold with their own hands<br>And even that keeps breaking."
      />

      {members.map((value, index) => (
        <Member
          key={index}
          index={index}
          copy={value.copy}
          cigar={value.cigar}
          name={value.name}
          position={value.position}
          image={value.image}
          social={{
            github: value.social.github,
          }}
        />
      ))}
    </StyledMembers>
  );
};
