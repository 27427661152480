import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";

library.add(fas, fab);

export const github = findIconDefinition({ prefix: "fab", iconName: "github" });
export const facebook = findIconDefinition({
  prefix: "fab",
  iconName: "facebook-square",
});
export const twitter = findIconDefinition({
  prefix: "fab",
  iconName: "twitter",
});
