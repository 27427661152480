import styled from "styled-components";

const StyledVerticalText = styled.div`
  p {
    writing-mode: vertical-rl;
    position: absolute;
    font-size: 1rem;
    letter-spacing: 0.8em;
    white-space: nowrap;
    &::before {
      content: "";
      position: absolute;
      top: 105%;
      left: 0.6rem;
      width: 0.1rem;
      height: 10rem;
      background-color: ${({ theme }) => theme.primaryWhite};
    }
  }
`;

export { StyledVerticalText };
