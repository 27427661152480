import React from "react";
import { bool, func } from "prop-types";

import { StyledMenu } from "./StyledMenu";
import { GlobalNavigation } from "../GlobalNavigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { github, facebook, twitter } from "../Icons";

export const Menu = ({ open, setOpen }) => {
  return (
    <StyledMenu open={open} setOpen={setOpen}>
      <GlobalNavigation open={open} setOpen={setOpen} />

      <ul className="social">
        <li>
          <a href="https://github.com/jirettai/">
            <FontAwesomeIcon icon={github} />
          </a>
          <a href="https://facebook.com/jirettai/">
            <FontAwesomeIcon icon={facebook} />
          </a>
          <a href="https://twitter.com/jirettai/">
            <FontAwesomeIcon icon={twitter} />
          </a>
        </li>
      </ul>
    </StyledMenu>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
  func: func,
};
