import React from "react";
import PropTypes from "prop-types";

import { StyledVerticalText } from "./StyledVerticalText";

const VerticalText = ({ text }) => (
  <StyledVerticalText>
    <p>{text}</p>
  </StyledVerticalText>
);

VerticalText.propTypes = {
  text: PropTypes.string,
};

export { VerticalText };
