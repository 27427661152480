import React from "react";

import { Logo } from "./elements/Logo";
import { Parallax } from "./elements/Parallax";
import { Members } from "./elements/Members";
import { Footer } from "./elements/Footer";
import { AnotherAngle } from "./elements/AnotherAngle";
import { OurProducts } from "./elements/OurProducts";
import { Topview } from "./elements/Topview";
import { ScrollIndicator } from "./elements/ScrollIndicator";

export const Home = () => {
  return (
    <>
      <Topview />
      <ScrollIndicator />
      <AnotherAngle />
      <OurProducts />
      <Purpose />
      <Members />
      <Footer />
    </>
  );
};

const Purpose = () => <Parallax />;

export const Header = () => <Logo />;
