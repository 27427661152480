import styled, { css } from "styled-components";

import { mq } from "../../styles/mixin";

const membersPageCss = css`
  position: absolute;
`;

export const StyledHeader = styled.header`
  width: 100%;
  padding-left: 10rem;
  margin: 0 auto;
  padding-top: 6rem;
  ${mq.sp`
    padding-top: 4.5rem;
    padding-left: 2.5rem;
  `}

  ${({ page }) => page.includes("/members/") && membersPageCss}
`;
