import React from "react";
import { useParams } from "react-router";
import { StyledMemberPage } from "./index.styled";
import { Hero } from "../../elements/Hero";
import { Biography } from "../../elements/Biography";
import { SkillTable } from "../../elements/SkillTable";
import { SectionLabel } from "../../elements/SectionLabel";

import data from "./data";
import { Footer } from "../../elements/Footer";

const MemberPage = () => {
  let { id } = useParams();

  const member = () => {
    switch (id) {
      case "oliver":
        return data.oliver;
      case "david":
        return data.david;
      case "abdul":
        return data.abdul;
      case "wilson":
        return data.wilson;
      case "astin":
        return data.astin;
      case "nikita":
        return data.nikita;
      case "jesse":
        return data.jesse;
      default:
    }
  };
  return (
    <StyledMemberPage>
      <Hero member={id} />
      <main>
        <Biography content={member(id).biography} />
        <SectionLabel
          title="Core Value"
          jpHeading="自分たちの手で新しい型を作り<br>
      そしてそれさえも壊し続ける。"
          enHeading="We step outside our box to create <br> unprecedented work to discover new ourselves."
          verticalText="We are geek crazy creator"
        />
        {Object.keys(member(id).skills).map((header, index) => (
          <SkillTable
            key={index}
            header={header}
            arr={member(id).skills[header]}
            index={index}
          />
        ))}
      </main>
      <Footer />
    </StyledMemberPage>
  );
};

export { MemberPage };
