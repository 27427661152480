import React from "react";

import { StyledAnotherAngle } from "./StyledAnotherAngle";
import { Outline } from "../Outline";

export const AnotherAngle = () => {
  return (
    <div className="container my-6 sp-mt-0 sp-pt-0">
      <StyledAnotherAngle>
        <div className="outline">
          <Outline align="right" title="Another<br>angle" />
        </div>
        <div className="message-box">
          <div className="japanese_message">
            JIRETTAIは <br />
            尖ったクリエイターたちの実験場。
            <br />
            常識を疑い誰もが当たり前だと
            <br className="sp-break" />
            思っている物事に <br />
            一味違う視点を持ち込む。
            <br />
            今までの型を壊し、 <br />
            自分たちの手で新しい型を作り、 <br />
            そしてそれさえも壊し続ける <br />
            そんな、じれったさと向き合い続ける。
            <br />
            情熱が未来を作る <br />
            未来の当たり前は、
            <br className="sp-break" />
            たちの発明から始まる。
          </div>
          <div className="english_message">
            JIRETTAI
            <br />
            The experimental field for edgy creators.
            <br />
            We doubt the common sense and bring unique thoughts into things
            <br className="pc-break" />
            people taking them for granted.
            <br />
            We step outside our box to create unprecedented work
            <br className="pc-break" />
            to discover <br className="sp-break" /> new ourselves.
            <br />
            Such passion to keep on facing hardships creates the future.{" "}
            <br className="pc-break" />
            Our creation is where the future standard begins.
            <br className="pc-break" />
          </div>
        </div>
      </StyledAnotherAngle>
    </div>
  );
};
