import { createGlobalStyle } from "styled-components";

import { mq } from "./mixin";

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%;
    overflow-x: hidden;
  }

  @font-face {
    font-family: 'YuGothic-Bold';
    src: url('../font/YuGothic-Bold.otf');
  }

  @font-face {
    font-family: 'YuGothic-Medium';
    src: url('../font/YuGothic-Medium.otf');
  }

  @font-face {
    font-family: ${({ theme }) => theme.outlineFont};
    src: url('../font/STHEITI.ttf');
  }

  body {
    box-sizing: border-box;
    font-size: 1.6rem;
    font-family: Lato, sans-serif;
    font-weight: 600;
    background-color: ${({ theme }) => theme.primaryDark};
    color: ${({ theme }) => theme.primaryLight};
    text-rendering: optimizeLegibility;
    position: relative;
    width: 100%;
    overflow-x: hidden;
  }

  li {
    list-style:none;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.primaryLight};
    &:hover {
      text-decoration:none;
      color: ${({ theme }) => theme.primaryHover};
    }
  }

  .container {
    margin:0 auto;
    padding: 0 10rem;
    max-width: ${({ theme }) => theme.containerWidth};
    ${mq.sp`
      padding: 10rem 2rem;
    `}
  }

  .visible-sp {
    display: none;
    ${mq.sp`
      display: block;
    `}
  }
  .invisible-sp {
    ${mq.sp`
     display:none
    `}
  }
  
  .sectionContainer {
    padding: 20rem 10rem;
    ${mq.sp`
      padding: 10rem 2rem;
    `}
  }

  .inner {
    margin: 0 auto;
    width: 80%;
    ${mq.sp`
    width: 90%;
    `}
  }

  .text {
    &--red {
      color: ${({ theme }) => theme.primaryHighlight}
    }
  }

  .pc-break {
    display: block;
    ${mq.sp`
      display: none;
    `}
  }

  .tb-break {
    ${mq.tb`
      display: block;
    `}
  }
  
  .sp-break {
    display: none;
    ${mq.sp`
      display: block;
    `}
  }
  
  .vertical {
    writing-mode: vertical-rl;
  }
  .vertical-swap {
    transform: rotate(180deg);
  }

  .px-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .my-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .my-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .my-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .mx-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mx-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .mx-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .mb-1 {
    margin-bottom: 1rem;
  }

  .mb-3 {
    margin-bottom: 3rem;
  }

  .mb-6 {
    margin-bottom: 6rem;
  }

  .mt-1 {
    margin-top: 1rem;
  }

  .mt-3 {
    margin-top: 3rem;
  }

  .mt-6 {
    margin-top: 6rem;
  }

  .sp-mt-0 {
    ${mq.sp`
      margin-top: 0;
    `}
  }

  .sp-pt-0 {
    ${mq.sp`
      padding-top: 0;
    `}
  }

`;
