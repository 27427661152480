import styled from "styled-components";
import { mq } from "../../styles/mixin";

const StyledMemberPage = styled.div`
  main {
    padding: 0 12rem;
    ${mq.sp`
    padding: 0 4rem;
  `}
  }
`;

export { StyledMemberPage };
