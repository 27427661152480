import React from "react";
import PropTypes from "prop-types";

import { StyledSectionLabel } from "./StyledSectionLabel";
import { Outline } from "../Outline";
import { multipleLineText } from "../../util";

export const SectionLabel = ({
  className,
  title,
  verticalText,
  jpHeading,
  enHeading,
}) => {
  return (
    <StyledSectionLabel wide={title.includes("<br>")} className={className}>
      <Outline title={title} />
      <p className="vertical">{verticalText}</p>

      <div className="text-block">
        <div className="about-japanese">{multipleLineText(jpHeading)}</div>
        <div className="about-english">{multipleLineText(enHeading)}</div>
      </div>
    </StyledSectionLabel>
  );
};

SectionLabel.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  verticalText: PropTypes.string,
  jpHeading: PropTypes.string,
  enHeading: PropTypes.string,
};
