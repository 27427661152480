import React from "react";
import { StyledBiography } from "./index.styled";

const Biography = ({ content }) => {
  function createMarkup() {
    return { __html: content };
  }
  return (
    <StyledBiography>
      <p dangerouslySetInnerHTML={createMarkup()} />
    </StyledBiography>
  );
};

export { Biography };
