import styled from "styled-components";
import { mq } from "../../styles/mixin";

const StyledBiography = styled.div`
  width: 100%;
  max-width: 768px;
  margin: 16rem auto;

  ${mq.sp`
    margin: 8rem auto;
  `}
  p {
    line-height: 3;
    font-size: 1.4rem;
    font-weight: 400;
    ${mq.sp`
      font-size: 1.2rem;
      line-height: 2.7;
    `}
  }
`;

export { StyledBiography };
