import React from "react";
import { Link } from "react-router-dom";

import { StyledLogo } from "./StyledLogo";
import logoBlack from "../../images/Logo/logo-black.png";
import logoWhite from "../../images/Logo/logo-white.png";

export const Logo = props => {
  const src = props.color === "black" ? logoBlack : logoWhite;

  return (
    <StyledLogo>
      <Link to={"/"}>
        <img src={src} alt="logo" />
      </Link>
    </StyledLogo>
  );
};
