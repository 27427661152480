import React from "react";
import { MemberLabel } from "../_atoms/MemberLabel";
import { StyledHero } from "./index.styled";
import { members } from "../Members/membersInfo";

const Hero = ({ member }) => {
  const nameToIndexMap = {
    oliver: 0,
    david: 1,
    abdul: 2,
    wilson: 3,
    astin: 4,
    nikita: 5,
    jesse: 6,
  };

  const memberInfo = member => {
    const index = nameToIndexMap[member];

    if (index !== undefined) {
      const info = members[index];
      return {
        index: index + 1,
        copy: { __html: `${info.copy}<br> ${info.cigar} cigar` },
        position: { __html: `${info.name} / ${info.position}` },
      };
    } else {
      return {
        index: 0,
        copy: { __html: "Mr. Robot" },
        position: { __html: "Anonymous Hacker" },
      };
    }
  };
  const currentMemberInfo = memberInfo(member);

  return (
    <StyledHero member={member}>
      <div className="text-wrapper">
        <MemberLabel index={currentMemberInfo.index} />
        <h1 dangerouslySetInnerHTML={currentMemberInfo.copy} />
        <p dangerouslySetInnerHTML={currentMemberInfo.position} />
      </div>
    </StyledHero>
  );
};

export { Hero };
