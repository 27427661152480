import React from "react";
import { Link } from "react-scroll";
import { bool, func } from "prop-types";
import { motion } from "framer-motion";

import { StyledGlobalNavigation } from "./StyledGlobalNavigation";
import { MenuItem } from "../MenuItem";
import { Outline } from "../Outline";
import { VerticalText } from "../VerticalText";
const variants = {
  list: {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  },
  item: {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  },
};

// Not going to use until other pages are done
// const links = [
//   {
//     path: "/",
//     name: "Home",
//   },
//   {
//     path: "/services",
//     name: "Services",
//   },
//   {
//     path: "/contacts",
//     name: "Contacts",
//   },
//   {
//     path: "/product",
//     name: "Product",
//   },
//   {
//     path: "/members",
//     name: "Members",
//   },
//   {
//     path: "/released",
//     name: "Released",
//   },
// ];

const scrollLinks = [
  {
    id: "our-products",
    name: "Our Products",
  },
  {
    id: "about-us",
    name: "About Us",
    offset: 100,
  },
  {
    id: "our-union-lab",
    name: "Our Union Lab",
    offset: 100,
  },
];

export const GlobalNavigation = ({ open, setOpen }) => {
  return (
    <StyledGlobalNavigation>
      <motion.ul variants={variants.list}>
        {scrollLinks.map((item, i) => (
          <motion.li
            key={i}
            variants={variants.item}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            open={open}
          >
            <Link
              activeClass="active"
              to={item.id}
              spy={true}
              smooth={true}
              offset={item.offset}
              duration={500}
              onClick={() => setOpen(!open)}
            >
              <MenuItem i={i} key={i} name={item.name} />
            </Link>
          </motion.li>
        ))}
      </motion.ul>
      <div className="outline">
        <Outline align="right" title="Another<br>angle" />
      </div>
      <div className="vertical-text">
        <VerticalText text="Products for creator" />
      </div>
    </StyledGlobalNavigation>
  );
};

GlobalNavigation.propTypes = {
  open: bool.isRequired,
  func: func,
};
