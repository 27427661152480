// Menu.styled.js
import styled from "styled-components";

import { mq } from "../../styles/mixin";

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.primaryDark};
  height: 100svh;
  text-align: left;
  padding: 20vw;
  ${mq.sp`
    padding: 2.5rem;
  `}
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  width: 100%;
  z-index: 2;
  a {
    font-size: 4rem;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: ${({ theme }) => theme.primaryLight};
    text-decoration: none;
    transition: color 0.3s linear;
    ${mq.sp`
      font-size: 2.4rem;
      text-align: center;
    `}
    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }

  .social {
    /* position: absolute; */
    /* bottom: 8vh;
    left: 20vw; */
    /* ${mq.sp`
      left: 2.5rem;
    `} */
    a {
      color: ${({ theme }) => theme.white};
      font-size: 3rem;
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }
`;
