import React from "react";

import { StyledTopView } from "./StyledTopview";

import topBgImage from "../../images/top/jirettai-group.png";
import topBgSpUpperImage from "../../images/top/topview-sp-upper.png";
import topBgSpLowerImage from "../../images/top/topview-sp-lower.png";

export const Topview = () => (
  <StyledTopView>
    <div className="group_image">
      <img src={topBgImage} alt="Jrettai Group" width="100%" />
    </div>
    <div className="group_image_sp_upper">
      <img src={topBgSpUpperImage} alt="Jrettai Group SP Upper" width="100%" />
    </div>
    <div className="group_image_sp_lower">
      <img src={topBgSpLowerImage} alt="Jrettai Group SP Lower" width="100%" />
    </div>
    <div className="title_text">
      <p className="japanese">
        常識なんて
        <br />
        誰が決めた？
      </p>
      <p className="english">who decided the standard?</p>
    </div>
  </StyledTopView>
);
